import React from "react";
import Footer from "./Homepage/Footer/Footer";
import JoinForces from "./JoinForces";
import Navbar from "./navbar/Navbar";
import hijabMarketWoman from "../assets/images/hijabMarketWoman.png";

function ServicesAndLoans() {
  return (
    <div className="service-container">
      <Navbar />
      <div className="text-and-image-container">
        <div className="text-area">
          <h1 className="text-area-header">Savings and Loans</h1>
          <p className="text-area-text">
            Finally, micro-business owners who do not have a bank account can
            now get one in under 5 minutes without the stressful paperwork and
            deposit their savings through our verified agents.
            <br />
            <br />
            Our lending service also allows them to buy an item today and pay
            later, settle their wards’ school fees in advance, purchase food
            items, and meet their business needs.
          </p>
        </div>
        <div className="img-area">
          <img src={hijabMarketWoman} alt="boy-and-girl" />
        </div>
      </div>
      <JoinForces />
      <Footer />
    </div>
  );
}

export default ServicesAndLoans;
