export const BackArrowIcon = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill={"none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Back Arrow</title>
    <path
      d="M17 7H1M1 7L7 13M1 7L7 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
