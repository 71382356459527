import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/Homepage/Footer/Footer";
import Bloggy from "../../components/blog/Bloggy";
import BlogImg from "../../assets/images/BlogImg.png";
import "./Blog.scss";
const Blog: React.FC = () => {
  return (
    <div className = 'wrapy'>
      <Navbar />
      <main className="Blowrap">
        <h1> Blog </h1>
        <p>Stay in the Know: Get Engaged with Regxta's Informative Blog</p>

        <div>
          <Bloggy
            img={BlogImg}
            head={"How to improve financial inclusion in Africa"}
            para={
              "One of the reasons micro businesses default on their obligations are because of their lack of understanding of the benefits of protecting the lives. 21st June 2023 Read More"
            }
          />
        </div>
        <div>
          <Bloggy
            img={BlogImg}
            head={"How to improve financial inclusion in Africa"}
            para={
              "One of the reasons micro businesses default on their obligations are because of their lack of understanding of the benefits of protecting the lives. 21st June 2023 Read More"
            }
          />
        </div>
        <div>
          <Bloggy
            img={BlogImg}
            head={"How to improve financial inclusion in Africa"}
            para={
              "One of the reasons micro businesses default on their obligations are because of their lack of understanding of the benefits of protecting the lives. 21st June 2023 Read More"
            }
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Blog;
