import React from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./Homepage/Footer/Footer";

function Services() {
  return (
    <main>
      <Navbar />

      <div>Services</div>
      <Footer />
    </main>
  );
}

export default Services;
