import React from "react";
import { Link } from "react-router-dom";

import PhoneImage from "../../../assets/images/phone-image.png";
import PhoneSm from "../../../assets/images/phone-sm.png";
import FirstCentral from "../../../assets/images/firstcentral.svg";
import IdentityPass from "../../../assets/images/identity.svg";
import Octamile from "../../../assets/images/octamile.svg";
import mizala from "../../../assets/images/mizala.svg";
import ProvidusLogo from "../../../assets/images/providus.svg";
import ArmLogo from "../../../assets/images/arm.svg";
import Barcode from "../../../assets/images/qr-code.svg";
import appstoree_icon from "../../../assets/images/app-store-black.png";
import playstore_icon from "../../../assets/images/google-play-black.png";
import { Fade, Zoom } from "../../..";
const TrustedBy = () => {
  return (
    <div className="trusted-by-container">
      <Fade>
        <div className="phone-info-box">
          <div className="phone-info-box-text-area">
            <img src={PhoneSm} className="phone-sm" alt="phone" />
            <h1 className="phone-info-box-header">Download Our App Today</h1>
            <div className="subtext-area">
              <p className="subtext">
                Every action you take is a step towards empowering others.
              </p>
            </div>

            <div className="subtext-area" style={{ marginTop: "5rem" }}>
              <p className="subtext">Available for Android and IOS</p>
            </div>

            <div className="barcode_container">
              <img src={Barcode} className="barcode" alt="triangles" />
              <div className="link_container">
                <Link to="https://play.google.com/store/apps/details?id=com.regxta.app">
                  <div className="">
                    <img src={playstore_icon} alt="playstore linke" />
                  </div>
                </Link>

                <Link to="https://apps.apple.com/us/app/regxta-business/id6467222864">
                  <div className="">
                    <img src={appstoree_icon} alt="app store link" />
                  </div>
                </Link>
              </div>
            </div>
            {/* <div className="download_links">
            <Link to="https://play.google.com/store/apps/details?id=com.regxta.app">
              <img
                src={googlePlay}
                alt="playstore linke"
                className="download_icons"
              />
            </Link>
            <Link to="https://apps.apple.com/us/app/regxta-business/id6467222864">
              <img
                src={appstore}
                alt="app store link"
                className="download_icons"
              />
            </Link>
          </div> */}
          </div>
          <img src={PhoneImage} className="phone" alt="phone" />
        </div>
      </Fade>

      <Fade bottom>
        <div className="trusted-by">
          <h1 className="trusted-by-header">Trusted by</h1>
          <div className="logo-row">
            <img src={ProvidusLogo} className="logo" alt="providus" />
            <img src={ArmLogo} className="logo" alt="arm-logo" />
            <img src={IdentityPass} className="logo" alt="identity-pass" />
            <img src={Octamile} className="logo" alt="octamile" />
            <img src={FirstCentral} className="logo" alt="first-central" />
            <img src={mizala} className="logo" alt="mizala" />
          </div>
        </div>
      </Fade>
    </div>
  );
};
export default TrustedBy;
