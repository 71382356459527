import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactNode, useState } from "react";
import "./style.scss";
import { MinusIcon } from "../../../assets/icons/MinusIcon";
import { PlusIcon } from "../../../assets/icons/PlusIcon";
import { Fade } from "../../..";
import { HiMinusSm } from "react-icons/hi";
import { PlusCircle } from "../../../assets/icons/PlusCircle";

interface PricingFaqItem {
  id: number;
  question: string | boolean;
  content: string | ReactNode;
}
const SupportLink = () => (
  <div>
    Absolutely! We welcome partnerships that align with our mission to promote
    financial inclusion for the under-served. For more details please reach out
    to{" "}
    <a href="mailto:info@regxta.com" style={{ color: "#e3a721" }}>
      info@regxta.com
    </a>
  </div>
);

const faqs = [
  {
    id: 1,
    question: "What does Regxta do?",
    content:
      "Regxta offers digital financial solutions, including micro-loans, savings accounts, and business support services, aimed at empowering unbanked individuals and small businesses.",
  },
  {
    id: 2,
    question: "How does Regxta support its agents?",
    content:
      "We offer comprehensive training, resources, and ongoing support to empower our agents to serve their communities effectively.",
  },
  {
    id: 3,
    question: "Who can become an agent with Regxta?",
    content:
      "Anyone passionate about empowering their community and interested in financial services can become an agent. Download our app to get started!",
  },
  {
    id: 4,
    question: "Are there any fees associated with opening an account?",
    content:
      "No, it is completely free. We strive to keep costs low and we provide transparent information on any applicable fees.",
  },
  {
    id: 5,
    question: "Can I use Regxta services if I don’t have a bank account?",
    content:
      "Yes! Regxta is designed for unbanked individuals, providing them with the necessary tools to access financial services without a traditional bank account.",
  },
  {
    id: 6,
    question: "Can I partner with Regxta as an investor?",
    content: <SupportLink />,
  },
  {
    id: 7,
    question: "What are the benefits of partnering with Regxta as an investor?",
    content:
      "Investors can support a mission-driven company focused on financial inclusion, with opportunities to see their investments drive real change in the community.",
  },
];

const FaqSection: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="pd_price_faq" id="faq">
      <div className="faq_wrapper">
        <Fade>
          <h3 className="faq">Frequently Asked Questions</h3>
        </Fade>
        <section className="faq-custom">
          {faqs.map((item: PricingFaqItem) => (
            <Accordion
              expanded={expanded === item.question}
              onChange={handleChange(item.question)}
              className="accordion_box"
              key={item.id}
            >
              <AccordionSummary
                className="accordion_title"
                expandIcon={
                  expanded === item.question ? (
                    <PlusCircle className="rotate" />
                  ) : (
                    <PlusCircle />
                  )
                }
              >
                <h2 className="title">{item.question}</h2>
              </AccordionSummary>

              <AccordionDetails>
                <p className="content">{item.content}</p>
              </AccordionDetails>
            </Accordion>
          ))}
        </section>
        <h2 className="footer">
          Need additional support?{" "}
          <a href="mailto:info@regxta.com">
            <span className="orange-text">Contact us</span>
          </a>
        </h2>
      </div>
    </div>
  );
};

export default FaqSection;
