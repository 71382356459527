import React from "react";
import { Link } from "react-router-dom";

import joinForces from "../assets/images/hands.svg";

function JoinForces() {
  return (
    <div className="container">
      <div className="text-area">
        <h1 className="text-area-header">Invest in a Brighter Future</h1>
        <p className="text-area-text">
          Support a movement that prioritizes financial inclusion of the
          under-served
          <br />
          and affordable for everyone.
        </p>

        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSd6iE9acB-J54Gb2o-XG2--BLSZ2Qn3f1nVLheuvTSQebqgrQ/viewform"
          target="_blank"
          className="btn"
        >
          Partner With Us
        </Link>
      </div>
      <div className="img-area">
        <img src={joinForces} alt="join-forces" />
      </div>
    </div>
  );
}

export default JoinForces;
