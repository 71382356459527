import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Homepage/Footer/Footer";
import collage from "../../assets/images/collage.png";
// import afisAndRukky from "../../assets/images/afis-and-rukky.png";
import afisAndRukky from "../../assets/images/arr.jpg";
import advancioAward from "../../assets/images/advansioAward.png";
import getEquityAward from "../../assets/images/getEquityAward.png";
import oasisAward from "../../assets/images/oasisAward.png";
import CEO from "../../assets/images/profile.svg";
import COO from "../../assets/images/COO.png";
import CTO from "../../assets/images/CTO.png";
import DCTO from "../../assets/images/DCTO.png";
import armPensionLogo from "../../assets/images/arm-logo.png";
import getEquity from "../../assets/images/get-equity.png";
import midlothian from "../../assets/images/midlothian.png";
import unsungHeros from "../../assets/images/UNSUNG-Heroes.png";
import villageCapital from "../../assets/images/village-capital.png";
import { BlueLinkedin } from "../../assets/SvgPack";

function AboutUs() {
  return (
    <main>
      <Navbar />
      <div className="about-us-container">
        <div className="top-text-area">
          <div className="left-container">
            <h1 className="our-vision">Our Vision</h1>
            <p className="left-container-text">
              “To become the world's most essential bank for the underserved
              market in africa”
            </p>
          </div>
          <div className="right-container">
            <h1 className="right-container-header">Who we are</h1>
            <p className="right-container-text">
              We are an inclusive digital platform providing quick, easy and
              simple access to financial services for the unbanked people and
              micro businesses in rural and peri urban communities in Africa,
              including the internally displaced people and refugees. As a
              result, we set out to connect this population to the formal
              financial sector, using a customized digital platform to establish
              credit histories and build business models, so that they could
              become financially free.
            </p>
          </div>
        </div>
        <div className="collage-container">
          <img src={collage} alt="collage" className="collage-image" />
        </div>
        <div className="short-story">
          <div className="afis-and-rukky-image">
            <img
              src={afisAndRukky}
              alt="afis-and-rukky"
              // width={400}
              // height={400}
              // style={{borderRadius: "12px"}}
            />
          </div>
          <div className="para-right">
            <h1 className="para-right-header">A Short Story</h1>
            <p className="para-right-text">
              Regxta was inspired by Rukayat’s mum, Rukayat started The Bells
              Dynamic Option as a part time money lending business to provide
              finance to underserved micro business owners like her Mum. 
              <br />
              <br />
              In January 2021, she broke new ground by launching Regxta as a
              digital platform to offer quick, easy and affordable financial
              solutions to the underserved micro business owners. The brand
              vision has also expanded beyond lending to include digital
              wallets, savings, payment solutions, micro insurance, pension,
              health services and mortgage. <br /> <br /> The platform also
              helps micro businesses to restructure and keep financial records.
              As Rukayat says ‘’Economic growth, poverty reduction, community
              development and job creations are deep rooted in driving financial
              inclusion in underserved communities. We prioritize women the
              most, because they are loyal, faithful and committed to help their
              families grow. <br /> <br />
              Regxta is here to democratize digital financial services and
              promote financial freedom  for the underserved people.
            </p>
          </div>
        </div>
        <div className="awards-and-leadership">
          <h1 className="awards-header">Awards</h1>
          <p className="awards-text">We're doing our best over here.</p>
          <div className="awards">
            <img src={advancioAward} alt="advancio-award" />
            <img src={getEquityAward} alt="get-equity-award" />
            <img src={oasisAward} alt="oasis-award" />
          </div>

          <div className="leadership">
            <h1 className="leadership-header">Leadership Team</h1>
            <p className="leadership-text">
              We are a group of passionate technology entrepreneurs working to
              customize financial solutions for people in our community and
              around the world to address the lack of access to financial
              services and to reduce poverty and hunger. Our team has over 25
              years of combined experience working in traditional banks and
              financial technology companies in Nigeria across various areas
              including business development, business operations, information
              technology, risk management, and customer experience.
            </p>
            <div className="image-container">
              <div className="single-image-container">
                <img src={CEO} alt="ceo-image" className="leader-image" />
                <div className="leader-description">
                  <div className="flex-start">
                    <h1 className="leader-name">Rukayat Kolawole</h1>
                    <p className="leader-title">CEO</p>
                  </div>
                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/rukayat-kolawole-07ab78156/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="single-image-container">
                <img src={CTO} alt="cto-image" className="leader-image" />
                <div className="leader-description">
                  <div>
                    <h1 className="leader-name">Moses Obika</h1>
                    <p className="leader-title">CTO</p>
                  </div>
                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/moses-obika-b43978156/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="single-image-container">
                <img src={COO} alt="coo-image" className="leader-image" />
                <div className="leader-description">
                  <div>
                    <h1 className="leader-name">Afis Bello</h1>
                    <p className="leader-title">COO</p>
                  </div>
                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/afis-bello-b070b4124/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="single-image-container">
                <img src={DCTO} alt="dcto-image" className="leader-image" />
                <div className="leader-description">
                  <div>
                    <h1 className="leader-name">Henry Ozoani</h1>
                    <p className="leader-title">DCTO</p>
                  </div>

                  <div className="mt-10">
                    <a
                      href="https://www.linkedin.com/in/ozoani-chibuike-henry/"
                      target="_blank"
                    >
                      <BlueLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="investors">
            <h1 className="investors-header">Our Investors</h1>
            <p className="investors-text">
              Our investors are well committed in ensuring we meet the
              stakeholders’ expectations
            </p>
            <div className="investors-logos">
              <img src={armPensionLogo} alt="our-investors" />
              <img src={getEquity} alt="our-investors" />
              <img src={midlothian} alt="our-investors" />
              <img src={unsungHeros} alt="our-investors" />
              <img src={villageCapital} alt="our-investors" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default AboutUs;
