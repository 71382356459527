import "./FinancialInitiation.scss";
import goals from "../../../assets/images/goals.png";
import community from "../../../assets/images/community.png";
import { GreenTick } from "../../../assets/SvgPack";
import woman from "../../../assets/images/happy-market-woman.png";
import { Fade, Zoom } from "../../..";

const FinancialInitiation = () => {
  const sections = [
    {
      id: 1,
      title: "Empowering Local Ambassadors",
      subtitle:
        "We train agents to become champions in their communities, helping microbusiness owners to:",
      list: [
        "Open accounts with ease",
        "Register their businesses without hassle",
        "Manage their expenses efficiently",
      ],
      image: woman,
    },
    {
      id: 2,
      title: "Building a Financial System for the Everyday Hero",
      subtitle: "With Regxta, microbusinesses can:",
      list: [
        "Access user-friendly online banking",
        "Simplify payment processes",
        "Secure your business credit in no time",
        "Monitor earnings using our intuitive financial record generator",
      ],
      image: community,
    },
    {
      id: 3,
      title: "Aligned with Sustainable Development Goals",
      subtitle: "We align our products to help solve the following SDGs:",
      list: [
        "SDG 1: No Poverty",
        "SDG 2: Zero Hunger",
        "SDG 3: Good Health and Well-Being",
        "SDG 4: Quality Education",
        "SDG 5: Decent Work and Economic Growth",
      ],
      image: goals,
    },
  ];

  return (
    <div className="content2">
      <Fade>
        <div className="header_wrapper">
          <h1 className="why-we-different-header">Why We Stand Out</h1>
          <p
            className="why-we-different-par-one-right-text"
            style={{ marginTop: "1rem" }}
          >
            Over <span>10,000</span> merchants from diverse geographical
            locations trust us to propel their financial development.
          </p>
        </div>
      </Fade>
      <div className="first_wrapper">
        <div className="scroll_wrapper">
          {sections.map((section) => (
            <div className="why-we-different-container">
              <Zoom>
                <div className="why-we-different-par-one-right">
                  <img
                    src={section.image}
                    className="why-we-different-image"
                    alt="current"
                  />
                </div>
              </Zoom>
              <Zoom>
                <div className="why-we-different-text-area">
                  <div
                    key={section.id}
                    id={`section-${section.id}`}
                    className="text-section"
                  >
                    <h1 className="why-we-different-header">{section.title}</h1>
                    <p className="why-we-different-text">{section.subtitle}</p>
                    {section.list.map((item, index) => (
                      <p key={index} className="why-we-different-text">
                        <span className="tick-icon">
                          <GreenTick
                            fill={
                              section.id === 1
                                ? "#E3A721"
                                : section.id === 2
                                ? "#743674"
                                : section.id === 3
                                ? "#507350"
                                : undefined
                            }
                          />{" "}
                        </span>{" "}
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </Zoom>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinancialInitiation;
