import React from "react";
import { GreenTick } from "../../../assets/SvgPack";
import "./WhyWeDifferent.scss";
import Woman from "../../../assets/images/hero1.svg";
import { Fade } from "../../..";

function WhyWeDifferent() {
  return (
    <div className="content1">
      <div className="header_wrapper"></div>
      <div className="why-we-different-container diff-fin">
        <Fade bottom>
          <div className="why-we-different-text-area">
            <h1 className="why-we-different-par-one-right-header ">
              Crafting Financial Inclusion
            </h1>
            <p className="why-we-different-par-one-right-text">
              Welcome to the initiation of financial inclusion! Our platform is
              a beacon of hope, providing swift, affordable online banking
              services, business loans, and robust support for those often
              overlooked.
            </p>
            <h1 className="why-we-different-header">
              A Monetary Structure <br /> Beneficial to All Individuals
            </h1>
            <p className="why-we-different-text">
              Our product structure and solutions help to:
            </p>
            <p className="why-we-different-text">
              <span className="tick-icon">
                <GreenTick fill={"#E3A721"} />
              </span>{" "}
              Speed up business transaction processes
            </p>
            <p className="why-we-different-text">
              <span className="tick-icon">
                <GreenTick fill={"#E3A721"} />
              </span>{" "}
              Lower business risks
            </p>
            <p className="why-we-different-text">
              <span className="tick-icon">
                <GreenTick fill={"#E3A721"} />
              </span>{" "}
              Encourage savings and investment
            </p>
            <p className="why-we-different-text">
              <span className="tick-icon">
                <GreenTick fill={"#E3A721"} />
              </span>{" "}
              Reduce transaction and information costs
            </p>
          </div>
        </Fade>

        <div className="why-we-different-par-one-right">
          <Fade bottom>
            <img
              src={Woman}
              className="why-we-different-image"
              alt="happy-market-woman"
            />
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default WhyWeDifferent;
