import React from "react";
import Footer from "./Homepage/Footer/Footer";
import JoinForces from "./JoinForces";
import Navbar from "./navbar/Navbar";
import twoOldMen from "../assets/images/twoOldMen.png";
import '../styles/sass/DigitalWallet.scss'

function DigitalWallets() {
  return (
    <div className="service-container">
      <Navbar />
      <div className="text-and-image-container">
        <div className="text-area">
          <h1 className="text-area-header">Digital Wallets</h1>
          <p className="text-area-text">
            We aggregate customized data of the unbanked and micro businesses
            and award them credit scores on our innovative digital platform.
            <br />
            <br /> We create transactional accounts for our customers using
            their phone numbers. Our users can send and receive payments through
            the wallets safely and conveniently. <br />
            <br />
            Digital wallets are available to consumers free of charge, and
            they're fairly easy to obtain. E-wallets provide users with a
            detailed record of their transactions, making it easier to make a
            follow up on spending and manage budgets. They can pay for bills and
            purchase airtime using the wallets.
          </p>
        </div>
        <div className="img-area">
          <img src={twoOldMen} alt="boy-and-girl" />
        </div>
      </div>
      <JoinForces />
      <Footer />
    </div>
  );
}

export default DigitalWallets;
