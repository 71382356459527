import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import nigerianFlag from "../../assets/images/nigerianFlag.png";
import personIcon from "../../assets/images/personIcon.png";
import { Link } from "react-router-dom";
import About from "../AboutUs/AboutUs";
// import logo from '../../../src/assets/images/logoRegxta.png'
import logo from "../../../src/assets/images/logo.svg";
import arrow from "../../../src/assets/images/chevron_big_down.svg";

import {
  AboutUs,
  BusinessSupport,
  ContactUs,
  DigitalMonitoring,
  DigitalWallet,
  OtherFinancialServices,
  PartnerWithUs,
  SavingsandLoans,
} from "../../assets/SvgPack";

const Navbar = () => {
  const [toggleNavItem, setToggleNavItem] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsHovered2(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
    setIsHovered(false);
  };
  const handleOthers = () => {
    setIsHovered2(false);
    setIsHovered(false);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768 && toggleNavItem) {
        setToggleNavItem(false);
      }
    });
  }, [toggleNavItem]);

  const handleToggle = () => {
    setToggleNavItem(!toggleNavItem);
  };
  return (
    <div className="navWrap" onMouseLeave={handleOthers}>
      <div className="nav">
        <div className="logo_mobile">
          <Link to="/">
            <img src={logo} width="120px" height="30px" alt="logo" />
          </Link>
        </div>
        <ul className={`nav-item_container ${toggleNavItem && "toggle-item"}`}>
          <li
            className={`nav-item show ${isHovered && "hover-effect"}`}
            onMouseEnter={handleMouseEnter}
          >
            About
            <span className="arrow">
              <img src={arrow} />
            </span>
          </li>
          <li className={`nav-item show`} onMouseEnter={handleOthers}>
            <Link to="/technology">Technology</Link>
          </li>
          <li
            className={`nav-item show ${isHovered2 && "hover-effect"}`}
            onMouseEnter={handleMouseEnter2}
          >
            Services
            <span className="arrow">
              <img src={arrow} />
            </span>
          </li>
          <li className="nav-item" onMouseEnter={handleOthers}>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
        <div className="flag-icon">
          <img src={nigerianFlag} />
        </div>
        <div className="burger" onClick={handleToggle}>
          <GiHamburgerMenu size="30px" color="grey" />
        </div>
      </div>
      <div className={`dropy ${isHovered && "show-dropy"}`}>
        {/* <h1> Here we are </h1> */}
        {isHovered && (
          <div className="drop">
            <div>
              <h2 onClick={handleOthers}>
                <AboutUs />
                <Link to="/about-us" className="footer-col-2-text">
                  About Us
                </Link>
              </h2>
              <p>
                Discover how this digital banking platform is transforming into
                africa's most crucial financial institution for underbanked
                consumers.
              </p>
            </div>
            <div>
              <h2 onClick={handleOthers}>
                <PartnerWithUs />
                <Link
                  to="https://docs.google.com/forms/d/e/1FAIpQLSd6iE9acB-J54Gb2o-XG2--BLSZ2Qn3f1nVLheuvTSQebqgrQ/viewform"
                  className="footer-col-2-text"
                  target="_blank"
                >
                  {" "}
                  Partner With Us
                </Link>
              </h2>
              <p>
                Collaborate with our organization to develop ways for providing
                economic access for everyone.
              </p>
            </div>
            <div>
              <h2 onClick={handleOthers}>
                <ContactUs />
                <a href="#footer" className="footer-col-2-text">
                  Contact Us
                </a>
              </h2>
              <p>
                Reach out to our support team through chat or email at and speak
                with a real person,
              </p>
            </div>
          </div>
        )}
      </div>

      <div className={`dropy ${isHovered2 && "show-dropy"}`}>
        {/* <h1> We offer </h1> */}
        {isHovered2 && (
          <ul className="drop">
            <li>
              <h2 onClick={handleOthers}>
                <DigitalWallet />
                <Link to="/digital-wallets" className="footer-col-2-text">
                  Digital Wallets
                </Link>
              </h2>
              <p>
                Aggregating customized data for the unbanked to award them
                credit scores on our digital platform.
              </p>
            </li>

            <li>
              <h2 onClick={handleOthers}>
                <SavingsandLoans />
                <Link to="/savings-and-loans" className="footer-col-2-text">
                  Savings & Loans
                </Link>
              </h2>
              <p>
                Get a bank account without the stress and process savings &
                loans through our verified agents.
              </p>
            </li>

            <li>
              <h2 onClick={handleOthers}>
                <DigitalMonitoring />
                <Link to="/digital-monitoring" className="footer-col-2-text">
                  Digital Monitoring
                </Link>
              </h2>
              <p>
                Analysis of business data using our mobile agents and advanced
                data analytics to determine user credit eligibility and
                financial standing.
              </p>
            </li>

            <li>
              <h2 onClick={handleOthers}>
                <BusinessSupport />
                <Link to="/business-support" className="footer-col-2-text">
                  Business Support
                </Link>
              </h2>
              <p>
                Become a registered enterprise and manage your cash flow with
                our financial record generator.
              </p>
            </li>
            <li>
              <h2 onClick={handleOthers}>
                <OtherFinancialServices />
                <Link
                  to="/other-financial-services"
                  className="footer-col-2-text"
                >
                  Other Financial Services
                </Link>
              </h2>
              <p>
                Enjoy access to affordable healthcare services, pension
                insurance and micro-mortgage through our partnership
              </p>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Navbar;
