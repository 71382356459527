export const PlusCircle = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 22.5C6.47967 22.494 2.00606 18.0204 2 12.5V12.3C2.10993 6.80455 6.63459 2.42797 12.1307 2.5009C17.6268 2.57382 22.0337 7.0689 21.9978 12.5654C21.9619 18.0618 17.4966 22.4989 12 22.5ZM7 11.5V13.5H11V17.5H13V13.5H17V11.5H13V7.50002H11V11.5H7Z"
      fill="#E3A721"
    />
  </svg>
);
