import React from "react";
import Footer from "./Homepage/Footer/Footer";
import JoinForces from "./JoinForces";
import Navbar from "./navbar/Navbar";
import potter from "../assets/images/potter.png";

function BusinessSupport() {
  return (
    <div className="service-container">
      <Navbar />
      <div className="text-and-image-container">
        <div className="text-area">
          <h1 className="text-area-header">Business Support</h1>
          <p className="text-area-text">
            We nurture and structure micro businesses from a point where they
            have no bank account to another point where their businesses are
            registered and able to monitor their cash flows for business
            growth.   We help our users become a registered enterprise and
            manage their cash flow with our financial record generator.
            Therefore, they can view their businesses as a separate entity.
          </p>
        </div>
        <div className="img-area">
          <img src={potter} alt="boy-and-girl" />
        </div>
      </div>
      <JoinForces />
      <Footer />
    </div>
  );
}

export default BusinessSupport;
