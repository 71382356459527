import React from "react";
import { QuestionIcon } from "../../../assets/SvgPack";
import Client1 from "../../../assets/images/client1.png";
import Client2 from "../../../assets/images/client2.png";
import Client3 from "../../../assets/images/client3.png";
import Fatimoh from "../../../assets/images/Fatimoh.jpeg";
import { Fade } from "../../..";

const OurClients = () => {
  return (
    <div id="testimonials" className="client">
      {/* <div className="icon">
        <QuestionIcon />
      </div> */}
      <div className="our-clients-container">
        <Fade>
          <h1>What our customers say about us</h1>
        </Fade>
        <div className="content_wrapper">
          <div className="flex">
            <div className="box">
              <div className="text-wrapper">
                <p className="title">
                  Regxta has not only helped me to grow my business, they have
                  made hunger a thing of the past in my family.
                </p>
              </div>
              <div className="footer">
                <div className="image">
                  <img src={Client2} alt="client-one" />
                </div>
                <p className="name">Mulikat</p>
              </div>
            </div>
            <div className="box box-right">
              <div className="text-wrapper">
                <p className="title">
                  I am very new with Regxta. My Tailoring association introduced
                  us like few months ago and since then, I have no regret
                  joining them. They are supporting and growing our businesses.
                </p>
              </div>
              <div className="footer">
                <div className="image">
                  <img src={Client3} alt="client-one" />
                </div>
                <p className="name">Amaka</p>
              </div>
            </div>
          </div>
          <div className="flex flip">
            <div className="box flip ">
              <div className="text-wrapper">
                <p className="title">
                  Regxta is my best choice. The loans are very easy and
                  convenient for me to repay and always meet my needs.
                </p>
              </div>
              <div className="footer">
                <div className="image">
                  <img src={Client1} alt="client-one" />
                </div>
                <p className="name">Fabian</p>
              </div>
            </div>
            <div className="box flip box-right">
              <div className="text-wrapper">
                <p className="title">
                  Regxta has given me access to banking services that I would
                  not have ordinarily gotten anywhere because I am not educated.
                </p>
              </div>
              <div className="footer">
                <div className="image">
                  <img src={Fatimoh} alt="client-one" />
                </div>
                <p className="name">Fatimoh</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex">
          <div className="box">
            <div className="message">
              <p className="title">
                Regxta has not only helped me to grow my business, they have
                made hunger a thing of the past in my family.
              </p>
              <div className="footer">
                <div className="image">
                  <img src={Client2} alt="client-one" />
                </div>
                <p className="name">Mulikat</p>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="message">
              <p className="title">
                I am very new with Regxta. My Tailoring association introduced
                us like few months ago and since then, I have no regret joining
                them. They are supporting and growing our businesses.
              </p>
              <div className="footer">
                <div className="image">
                  <img src={Client3} alt="client-one" />
                </div>
                <p className="name">Amaka</p>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="message">
              <p className="title">
                Regxta is my best choice. The loans are very easy and convenient
                for me to repay and always meet my needs.
              </p>
              <div className="footer">
                <div className="image">
                  <img src={Client1} alt="client-one" />
                </div>
                <p className="name">Fabian</p>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="message">
              <p className="title">
                Regxta has given me access to banking services that I would not
                have ordinarily gotten anywhere because I am not educated.
              </p>
              <div className="footer">
                <div className="image">
                  <img src={Fatimoh} alt="client-one" />
                </div>
                <p className="name">Fatimoh</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurClients;
