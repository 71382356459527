import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import BecomeAnAgent from "./components/Homepage/BecomeAnAgent/BecomeAnAgent";
import GettingPaid from "./components/Homepage/LandingPage/LandingPage";
import OurProducts from "./components/Homepage/TrustedBy/TrustedBy";
import Footer from "./components/Homepage/Footer/Footer";
import JoinUs from "./components/Homepage/AgentBenefits/AgentBenefits";
import Navbar from "./components/navbar/Navbar";
import "./styles/sass/App.scss";
import Accordion from "./components/Homepage/Accordion/Accordion";
import "./styles/sass/App.scss";
import InsideAppSection from "./components/Homepage/InsideAppSection/InsideAppSection";
import TeamSection from "./components/Homepage/TeamSection/TeamSection";
import OurClients from "./components/Homepage/OurClients/OurClients";
import PartnerWithUs from "./components/Homepage/AgentBenefits/AgentBenefits";
import FinancialInitiation from "./components/Homepage/FinancalInitiation/FinancialInitiation";
import WhyWeDifferent from "./components/Homepage/WhyWeDifferent/WhyWeDifferent";
import About from "./components/AboutUs/AboutUs";
import JoinForces from "./components/JoinForces";
import FaqSection from "./components/Homepage/FaqSection/FaqSection";

function App() {
  return (
    <div className="App">
      <Navbar />
      <GettingPaid />
      <WhyWeDifferent />
      <FinancialInitiation />
      <JoinUs />
      <BecomeAnAgent />
      <OurProducts />
      {/* <TeamSection /> */}
      <OurClients />
      <JoinForces />
      {/* <Accordion /> */}
      <FaqSection />
      {/* <InsideAppSection /> */}
      <Footer />
    </div>
  );
}

export default App;
