import React from "react";
import Footer from "./Homepage/Footer/Footer";
import JoinForces from "./JoinForces";
import Navbar from "./navbar/Navbar";
import boyAndGirl from "../assets/images/boyAndGirl.png";

function OtherFinancialServices() {
  return (
    <div className="service-container">
      <Navbar />
      <div className="text-and-image-container">
        <div className="text-area">
          <h1 className="text-area-header">Other Financial Services</h1>
          <p className="text-area-text">
            One of the reasons micro businesses default on their obligations are
            because of their lack of understanding of the benefits of protecting
            the lives of their families and their businesses against any
            casualties.
            <br />
            <br />
            We educate our users on the importance of other financial products
            such as insurance, pension, mortgage, health services etc to secure
            their lives, their families and that of their businesses.
            <br />
            <br />
            We offer affordable healthcare services,pension, insurance and
            micro-mortgage through our partnership with OFIs.
          </p>
        </div>
        <div className="img-area">
          <img src={boyAndGirl} alt="boy-and-girl" />
        </div>
      </div>
      <JoinForces />
      <Footer />
    </div>
  );
}

export default OtherFinancialServices;
