import React from 'react'
import './blog.scss'
import Down from '../../../src/assets/images/down.png'

interface Blogg{
    head: string;
    img: string;
    para: string;
}
const Bloggy:React.FC<Blogg> = ({img, para, head}) => {
  return (
    <div className="blogWrap">
      {/* <div className='blogImage'>
        <img src={img} alt="" />
      </div>
      <div>
        <h2>{head}</h2>
        <p>{para}</p>
        <main>
          <small> 21st June 2023</small>
          <span>
            <small>Read More</small>
            <img src={Down} alt="" />
          </span>
        </main>
      </div> */}
    </div>
  );
}

export default Bloggy