import React from "react";
import Footer from "./Homepage/Footer/Footer";
import JoinForces from "./JoinForces";
import Navbar from "./navbar/Navbar";
import regxtaAgentExplaining from "../assets/images/regxtaAgentExplaining.png";

function DigitalMonitoring() {
  return (
    <div className="service-container">
      <Navbar />
      <div className="text-and-image-container">
        <div className="text-area">
          <h1 className="text-area-header">Digital Monitoring</h1>
          <p className="text-area-text">
            According to Statista in 2022, 80% of micro businesses in Africa
            fail annually due to lack of cash flow management. Regxta has
            designed a hybrid platform that helps micro businesses to keep
            adequate records of their business transactions and monitor their
            cash flow.
            <br />
            <br />
            We monitor our user’s business activities through our mobile agents
            and advanced data analytics. From the information generated, our
            users can understand the amount of credit they qualify for and
            evaluate their financial records.
          </p>
        </div>
        <div className="img-area">
          <img src={regxtaAgentExplaining} alt="boy-and-girl" />
        </div>
      </div>
      <JoinForces />
      <Footer />
    </div>
  );
}

export default DigitalMonitoring;
