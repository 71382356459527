import React from "react";
import Navbar from "./navbar/Navbar";
import Footer from "./Homepage/Footer/Footer";
import collage from "../assets/images/collage.png";

import twoPhones from "../assets/images/twoPhones.png";
import adminDashboard from "../assets/images/adminDashboard.png";
import smallPhone from "../assets/images/smallPhone.png";
import atmCards from "../assets/images/atmCards.png";
import POS from "../assets/images/POS.png";

function Technology() {
  return (
    <main>
      <Navbar />
      <div className="technology-container">
        <div className="text_wrapper">
          <div className="top-text-area">
            <div className="left-container">
              <h1 className="left-container-header">Mobile App</h1>
              <p className="left-container-text">
                Our mobile app is powered by advanced data science and artificial
                intelligence that instantly underwrites and disburses loans to
                people with no borrowing history. Our users can open an account,
                save, access micro pension and insurance and borrow money through
                our trained community agents. On the app, our users can keep
                records of their business transactions and monitor their cash
                flows.
              </p>
            </div>
            <div className="right-container">
              <img src={twoPhones} alt="two-Phones" />
            </div>
          </div>
          <div className="top-text-area">
           
            <div className="right-container right-second">
              <img src={adminDashboard} alt="admin-dashboard" className="border-img"/>
            </div>
            <div className="left-container">
              <h1 className="left-container-header">Loan Management System</h1>
              <p className="left-container-text">
              A digital platform which assists in automating and streamlining
                the entire loan life cycle including loan servicing, reporting,
                customer care, syndication and customer monitoring. It acts as a
                centralized data storage unit which is used for retaining and
                managing customer information, creating new loans, and keeping a
                record of all financial statements for the lenders. Thus, it
                offers an integrated overview of all the different processes of
                the lending lifecycle. This unified platform also has built in
                analytic modules that can be utilized for providing useful
                analysis and insights into automating the entire loan cycle as
                well as comprehensive reports on the cash flow.
              </p>
            </div>
          </div>
        
          <div className="top-text-area">
            <div className="left-container">
              <h1 className="left-container-header">Digital Wallets & USSD</h1>
              <p className="left-container-text">
                We create transactional accounts for our customers using their
                phone numbers. Our users can send and receive payments through the
                wallets safely and conveniently. Digital wallets are available to
                consumers free of charge, and they're fairly easy to obtain.
                E-wallets provide users with a detailed record of their
                transactions, making it easier to make a follow up on spending and
                manage budgets. They can pay for bills and purchase airtime using
                the wallets. USSD is also used by our customers to purchase mobile
                airtime and conduct data balance enquiries.
              </p>
            </div>
            <div className="right-container">
              <img src={smallPhone} alt="two-Phones" />
            </div>
          </div>
          <div className="top-text-area">
           
            <div className="right-container right-second">
              <img src={atmCards} alt="ATM cardss" />
            </div>
            <div className="left-container">
            <h1 className="coming-soon-text">Coming Soon</h1>
              <h1 className="left-container-header">Debit Cards</h1>
              <p className="left-container-text">
              Debit cards let our customers buy things without carrying cash.
                They can use your debit card in most stores to pay for something.
                Debit cards take money out of their wallets immediately. They can
                use the debit card at an automated teller machine, or ATM, to get
                money from their wallets and get cash back when it is used to buy
                something at a store.
              </p>
            </div>
          </div>
          
          <div className="top-text-area">
            <div className="left-container">
              <h1 className="coming-soon-text">Coming Soon</h1>
              <h1 className="left-container-header">POS/MPOS</h1>
              <p className="left-container-text">
                Our point of sale system is the technology that allows the
                business owners to receive card payments from a tablet or
                smartphone. These tools allow businesses to operate outside of a
                fixed location and can provide increased flexibility for business
                owners and customers. Besides processing transactions, these
                systems can also provide several services for small businesses.
              </p>
            </div>
            <div className="right-container">
              <img src={POS} alt="two-Phones" />
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </main>
  );
}

export default Technology;
