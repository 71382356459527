import pics from "../../../assets/images/Vector.png";
import diamond from "../../../assets/images/polygon.svg";
import circle from "../../../assets/images/circlesV2-shadow.svg";
import rhombus from "../../../assets/images/rombos-shadow.svg";
import round from "../../../assets/images/round.png";
import { useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/css/pagination";
import { RightArrowIcon } from "../../../assets/icons/RightArrowIcon";
import { BackArrowIcon } from "../../../assets/icons/BackArrowIcon";
import { Zoom } from "../../..";

const BecomeAnAgent = () => {
  // Explicitly type the ref as SwiperType or null
  const sliderRef = useRef<SwiperType | null>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.slidePrev(); // Access slidePrev method directly
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.slideNext(); // Access slideNext method directly
  }, []);

  const items = [
    {
      // title: "GET STARTED",
      title: "The Mission",
      text: "Picture yourself guiding someone toward their first savings account or helping a small business secure a microloan to expand. Each success story you help create contributes to a brighter future for all.",
      icon: round,
    },
    {
      title: "STEP 1",
      subtitle: "Download our mobile app",
      text: "Download the app from Google Playstore or Apple App Store",
      icon: diamond,
    },
    {
      title: "STEP 2",
      subtitle: "Sign up",
      text: "Follow the instructions provided and enter the required information accurately.",
      icon: circle,
    },
    {
      title: "STEP 3",
      subtitle: "Complete the KYC process",
      text: "This typically involves verifying your identity and collecting relevant information. This ensures the integrity of the financial services being provided to unbanked individuals.",
      icon: rhombus,
    },
    {
      title: "ALL SET",
      text: "Now you can onboard customers and provide them with the digital financial solutions your company offers.",
      icon: round,
    },
  ];

  return (
    <div id="become-an-agent" className="become-an-agent">
      <Zoom>
        <h1 className="header">Become an Agent in 3 steps</h1>
      </Zoom>
      <div className="become-an-agent-container">
        <div className="image_container">
          <img src={pics} className="image_container-img" alt="standing-lady" />
        </div>

        <div className="carousel_section">
          <Swiper
            onSwiper={(swiper) => (sliderRef.current = swiper)} // Assign Swiper instance to ref
            loop={true}
            pagination={false}
            navigation={false}
            autoplay={false}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {items.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="single_card_wrapper">
                  <div className="top_icon">
                    <img src={item.icon} alt={`Icon for ${item.title}`} />
                  </div>
                  <div className="single_card">
                    <h2 className="title">{item?.title}</h2>
                    <h4 className="subtitle">{item?.subtitle}</h4>
                    <p className="text">{item?.text}</p>
                  </div>
                  <div className="bottom_icon">
                    <img src={item.icon} alt={`Icon for ${item.title}`} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="nav_buttons">
            <div className="icon_wrap" onClick={handlePrev}>
              <div className="slide_btn_prev">
                <BackArrowIcon />
              </div>
            </div>
            <div className="icon_wrap" onClick={handleNext}>
              <div className="slide_btn_next">
                <RightArrowIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeAnAgent;
