import React, { useEffect, useState } from "react";
import CircleLines from "../../../assets/images/ornament.svg";
import Triangles from "../../../assets/images/triangles.svg";
import { Link } from "react-router-dom";

import Barcode from "../../../assets/images/qr-code.svg";
import appstoree_icon from "../../../assets/images/app-store-black.png";
import playstore_icon from "../../../assets/images/google-play-black.png";
import Typewriter from "../TypeWriter";
import { Zoom } from "../../..";
const LandingPage = () => {
  interface StyledWord {
    word: string;
    style: {
      color: string;
      // Other style properties if any
    };
  }

  const texts: { text: string; styledWords: StyledWord[] }[] = [
    {
      text: "Connecting Banking to Communities in a New Way",
      styledWords: [{ word: "Communities", style: { color: "#E1467E" } }],
    },

    {
      text: "Connecting Banking to Artisans in a New Way",
      styledWords: [{ word: "Artisans", style: { color: "#7A24D0" } }],
    },
    {
      text: "Connecting Banking to Smallholder Farmers in a New Way",
      styledWords: [
        { word: "Smallholder Farmers", style: { color: "#8CCA09" } }, //
      ],
    },
    {
      text: "Connecting Banking to Petty Traders in a New Way",
      styledWords: [{ word: "Petty Traders", style: { color: "#0990CA" } }], //
    },
    {
      text: "Connecting Banking to Street-Hawkers in a New Way",
      styledWords: [{ word: "Street-Hawkers", style: { color: "#CA0994" } }],
    },
    {
      text: "Connecting Banking to Low-Cost Schools in a New Way",
      styledWords: [{ word: "Low-Cost Schools", style: { color: "#CA3709" } }], //
    },
    {
      text: "Connecting Banking to Refugees and Immigrants in a New Way",
      styledWords: [
        { word: "Refugees and Immigrants", style: { color: "#04A1AB" } }, //
      ],
    },
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentTextObj = texts[currentTextIndex];
  const { text, styledWords } = currentTextObj;
  // return <div>{currentText}</div>;

  const TextComponent = ({
    text,
    styledWords,
  }: {
    text: string;
    styledWords: StyledWord[];
  }) => {
    // Function to style the specified words
    const getStyledText = (text: string, styledWords: StyledWord[]) => {
      const parts: JSX.Element[] = [];
      let startIndex = 0;

      for (const { word, style } of styledWords) {
        const index = text.indexOf(word, startIndex);
        if (index === -1) {
          break;
        }

        parts.push(
          <span key={startIndex}>{text.slice(startIndex, index)}</span>
        );
        parts.push(
          <span key={index} style={style}>
            {word}
          </span>
        );
        startIndex = index + word.length;
      }

      parts.push(<span key={startIndex}>{text.slice(startIndex)}</span>);

      return <div>{parts}</div>;
    };

    return getStyledText(text, styledWords);
  };

  return (
    <div className="landing-container" id="home">
      <img src={CircleLines} className="circle-lines" alt="circle-lines" />
      <div className="connecting">
        <p className="fade-in-out">
          Connecting Banking to <Typewriter /> <br />
          in a New Way
        </p>
      </div>

      <p className="earn-commision">
        We provide easy, quick and affordable digital financial solutions to
        unbanked individuals and micro businesses in rural and semi urban
        communities in Africa.
      </p>
      <div className="angle_wrapper">
        <img src={Triangles} className="triangles" alt="triangles" />
      </div>
      <div className="barcode-area">
        <div className="text"></div>
        <div className="links"></div>
      </div>

      <Zoom>
        <div className="barcode_container">
          <div className="barcode-container-text-area">
            <h1 className="barcode-container-header">Download Agent App</h1>
            <p className="barcode-container-text">
              Available for Android and IOS
            </p>
          </div>
          <img src={Barcode} className="barcode" alt="triangles" />
          <div className="link_container">
            <Link to="https://play.google.com/store/apps/details?id=com.regxta.app">
              <div className="">
                <img src={playstore_icon} alt="playstore linke" />
              </div>
            </Link>

            <Link to="https://apps.apple.com/us/app/regxta-business/id6467222864">
              <div className="">
                <img src={appstoree_icon} alt="app store link" />
              </div>
            </Link>
          </div>
        </div>
      </Zoom>
    </div>
  );
};

export default LandingPage;
