import React from "react";
import appstore from "../../../assets/images/appstore.png";
import googlePlay from "../../../assets/images/googlePlay.png";
import logo from "../../../assets/images/whitelogo.svg";
import phone from "../../../assets/images/phone.png";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { ImFacebook, ImLinkedin2 } from "react-icons/im";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  // Manually scroll to the top when the route changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div id="footer" className="footer-section">
      <div className="footer-container">
        <div className="footer-col-1">
          <div>
            <div>
              <img src={logo} width="131px" height="41px" alt="" />
            </div>
            <h1 className="footer-col-1-header">
              Connecting banking to customers in a new way
            </h1>
          </div>
        </div>
        <div className="section-right">
          <div className="footer-col-2">
            <h1 className="footer-col-2-header">Company</h1>
            <Link to="/about-us" className="footer-col-2-text">
              About us
            </Link>
            <Link to="/technology" className="footer-col-2-text">
              Technology
            </Link>
          </div>
          <div className="footer-col-2">
            <h1 className="footer-col-2-header">Services</h1>
            <Link to="/digital-wallets" className="footer-col-2-text">
              Digital Wallets
            </Link>
            <Link to="/savings-and-loans" className="footer-col-2-text">
              Savings & Loans
            </Link>
            <Link to="/digital-monitoring" className="footer-col-2-text">
              Digital Monitoring
            </Link>
            <Link to="/business-support" className="footer-col-2-text">
              Business Support
            </Link>
            <Link to="/other-financial-services" className="footer-col-2-text">
              Other Financial Services
            </Link>
          </div>
          <div className="footer-col-2">
            <h1 className="footer-col-2-header">Support</h1>
            <a className="footer-col-2-text" href="#home">
              Help Center
            </a>
            <a
              className="footer-col-2-text"
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/document/d/1nBxWHuAn-uB0co6h3mKjlhjTzTMFIWRuw6w5s2sCQNE/"
            >
              Privacy
            </a>
            <a
              className="footer-col-2-text"
              href="https://docs.google.com/document/d/1mirOqW7OP6DwD_DnyTmkDX-E6gvSbLa4VRc54iqiIQg/edit"
              target="_blank"
            >
              Terms
            </a>
          </div>
          <div className="footer-col-2">
            <h1 className="footer-col-2-header">Contact us</h1>
            <a className="footer-col-2-text" href="tel:+2347034868283">
              +234 703 486 8283
            </a>
            <a className="footer-col-2-text" href="mailto:info@regxta.com">
              info@regxta.com
            </a>
            <a className="footer-col-2-text" href="#team">
              101 Sari-Iganmu Rd, Iganmu 102272,<br></br> Lagos
            </a>
          </div>
          <div className="footer-col-4">
            <div>
              <img src={phone} className="phone-image" alt="phone image" />
            </div>
          </div>
        </div>
        <div className="horizontal-line"></div>

        <div className="flex-social">
          <div className="socials">
            <a
              href="https://www.instagram.com/regxta_"
              target="_blank"
              rel="noreferrer"
              className="icon"
            >
              <FaInstagram size={30} />
            </a>
            <a
              href="https://www.facebook.com/regXta"
              target="_blank"
              rel="noreferrer"
              className="icon"
            >
              <ImFacebook size={30} />
            </a>
            <a
              href="https://www.twitter.com/regxta_"
              target="_blank"
              rel="noreferrer"
              className="icon"
            >
              <FaTwitter size={30} />
            </a>
            <a
              href="https://www.linkedin.com/company/regxta-global-services"
              target="_blank"
              rel="noreferrer"
              className="icon"
            >
              <ImLinkedin2 size={30} />
            </a>
          </div>
          <div className="flex-center">
            <Link to="https://play.google.com/store/apps/details?id=com.regxta.app">
              <div className="">
                <img
                  src={googlePlay}
                  alt="playstore linke"
                  className="footer-apps"
                />
              </div>
            </Link>

            <Link to="https://apps.apple.com/us/app/regxta-business/id6467222864">
              <div className="">
                <img
                  src={appstore}
                  alt="app store link"
                  className="footer-apps"
                />
              </div>
            </Link>
          </div>
        </div>
        <h2 className="footer-bottom-text">
          Regxta is a registered technologies company with RC 1747728 as a duly
          registered legal <br></br> entity in Nigeria, with the Nigerian
          Corporate Affairs Commission.
        </h2>
      </div>
    </div>
  );
};
export default Footer;
